import PropTypes from 'prop-types';

const BlcColumnType = {
	/**
	 * Control the Column vertical alignment in the Row
	 */
	align: PropTypes.oneOf([
		'auto',
		'stretch',
		'flex-start',
		'flex-end',
		'center'
	]),
	/**
	 * Set amount of columns it takes in the Row
	 */
	col: PropTypes.number,
	/**
	 * Set the Column children
	 */
	children: PropTypes.node,
	/**
	 * Set a fixed height to the Column
	 */
	height: PropTypes.string,
	/**
	 * Set the Column margins
	 */
	margin: PropTypes.oneOf([
		'largest',
		'larger',
		'normal',
		'smaller',
		'smallest',
		'none'
	]),
	/**
	 * Set a starting height
	 */
	minHeight: PropTypes.number,
	/**
	 * Set a starting height in the mobile version
	 */
	 minHeightMobile: PropTypes.number,
	/**
	 * Set different order than the one in the markup
	 */
	order: PropTypes.number,
	/**
	 * Set the Column padding
	 */
	position: PropTypes.oneOf([
		'static',
		'relative',
		'absolute'
	]),
	/**
	 * Set the space between columns
	 * ColumnGutter is required but can be typed as because of this issue https://github.com/facebook/react/issues/4494
	 */
	columnGutter: PropTypes.oneOf([null,
		'smallest',
		'smaller',
		'normal',
		'larger',
		'largest',
		'none'
	]),
	/**
	 * Set a border between columns. Inherits Rows default value
	 */
	columnDivider: PropTypes.bool,
	/**
	 * Set the divider color. Inherits Rows default value
	 */
	columnDividerColor: PropTypes.string,
	/**
	 * Optionally send the column divider for each media size
	 */
	dividerOpt: PropTypes.object,
	/**
	 * Optionally send the column config for each media size
	 */
	colsOpt: PropTypes.object,
	/**
	 * Optionally send the column order for each media size
	 */
	orderOpt: PropTypes.object,
	/**
	 * Form specific prop
	 */
	errors: PropTypes.object,
	/**
	 * Form specific prop
	 */
	disabled: PropTypes.bool,
	/**
	 * Form specific prop
	 */
	handleBlur: PropTypes.func,
	/**
	 * Form specific prop
	 */
	isSubmitting: PropTypes.bool,
	/**
	 * Form specific prop
	 */
	setFieldValue: PropTypes.func,
	/**
	 * Form specific prop
	 */
	touched: PropTypes.object,
	/**
	 * Form specific prop
	 */
	values: PropTypes.object,
	/**
	 * Optionally send the column top position
	 */
	top: PropTypes.string,
	/**
	 * Optionally send the column right position
	 */
	right: PropTypes.string,
	/**
	 * Optionally send the column bottom position
	 */
	bottom: PropTypes.string,
	/**
	 * Optionally send the column left position
	 */
	left: PropTypes.string
};

export const BlcColumnDefaultProps = {
	align: 'auto',
	col: 12,
	children: null,
	height: 'unset',
	margin: 'none',
	minHeight: null,
	minHeightMobile: null,
	order: 0,
	position: 'relative',
	colsOpt: { mxl: null, ml: null, ms: null, mxs: null },
	orderOpt: { mxl: 0, ml: 0, ms: 0, mxs: 0 },
	dividerOpt: { mxl: true, ml: true, ms: true, mxs: true },
	errors: {},
	disabled: false,
	handleBlur: null,
	isSubmitting: false,
	setFieldValue: null,
	touched: {},
	values: {},
	top: 'unset',
	right: 'unset',
	bottom: 'unset',
	left: 'unset'
};

export const BlcColumnTypeProps = () => (<></>);

BlcColumnTypeProps.defaultProps = BlcColumnDefaultProps;

BlcColumnTypeProps.propTypes = BlcColumnType;

export default BlcColumnType;
