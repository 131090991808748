import { gutter, gutterML, gutterMS, gutterMXS } from 'helpers/gutter';
import { margin, marginML, marginMS, marginMXS } from 'helpers/margin';
import { columnLarger, columnSmaller, columnSmallest } from 'helpers/grid';
import { colors } from '@Backlot-Cars/archie';
import { media, size } from 'public/styles';
import styled from 'styled-components';

export const Wrapper = styled.div`
	align-self: ${props => props.align};
	${props => props.columnDivider && `border-left: ${size.border?.mxl} solid ${props => colors[props.columnDividerColor]};`}
	display: ${props => props.col === 0 || props.colsOpt?.mxl === 0 ? 'none' : 'block'};
	height: ${props => props.CSSHeight};
	margin: ${props => margin(props?.margin)} ${props => gutter(props.columnGutter) / 2 + `px`} ${props => props?.margin === 'none' ? gutter(props.columnGutter) + `px` : margin(props?.margin)};
	min-height: ${props => props?.minHeight ? props?.minHeight + 'px' : 'unset'};
	order: ${props => props.CSSOrder};
	position: ${props => props.position};
	width: calc(((100% / ${size.columns?.mxl}) * ${props => props.colsOpt?.mxl ? props.colsOpt?.mxl : props.col}) - ${props => gutter(props.columnGutter)}px);
	top: ${props => props.position && props.top};
	right: ${props => props.position && props.right};
	bottom: ${props => props.position && props.bottom};
	left: ${props => props.position && props.left};

	&:first-of-type { border-left: 0; }

	@media ${media.largest} {
			${props => props.columnDivider && props.dividerOpt?.mxl ? `border-left: ${size.border?.mxl} solid ${props => colors[props.columnDividerColor]};` : `border-left: 0;`}
			order: ${props => props.orderOpt?.mxl};
	}
	@media ${media.larger} {
			${props => props.columnDivider && props.dividerOpt?.ml ? `border-left: ${size.border?.ml} solid ${props => colors[props.columnDividerColor]};` : `border-left: 0;`}
			display: ${props => props.col === 0 || props.colsOpt?.ml === 0 ? 'none' : 'block'};
			margin: ${props => marginML(props?.margin)} ${props => gutterML(props.columnGutter) / 2 + `px`} ${props => props?.margin === 'none' ? gutterML(props.columnGutter) + `px` : marginML(props?.margin)};
			order: ${props => props.orderOpt?.ml};
			width: calc(((100% / ${size.columns?.ml}) * ${props => props.colsOpt?.ml ? props.colsOpt?.ml : columnLarger(props.col)}) - ${props => gutterML(props.columnGutter)}px);
	}
	@media ${media.smaller} {
			min-height: ${props => props?.minHeightMobile ? props?.minHeightMobile + 'px' : 'unset'};
			${props => props.columnDivider && props.dividerOpt?.ms ? `border-left: ${size.border?.ms} solid ${props => colors[props.columnDividerColor]};` : `border-left: 0;`}
			display: ${props => props.col === 0 || props.colsOpt?.ms === 0 ? 'none' : 'block'};
			margin: ${props => marginMS(props?.margin)} ${props => gutterMS(props.columnGutter) / 2 + `px`} ${props => props?.margin === 'none' ? gutterMS(props.columnGutter) + `px` : marginMS(props?.margin)};
			order: ${props => props.orderOpt?.ms};
			width: calc(((100% / ${size.columns?.ms}) * ${props => props.colsOpt?.ms ? props.colsOpt?.ms : columnSmaller(props.col)}) - ${props => gutterMS(props.columnGutter)}px);
	}
	@media ${media.smallest} {
			${props => props.columnDivider && props.dividerOpt?.mxs ? `border-left: ${size.border?.mxs} solid ${props => colors[props.columnDividerColor]};` : `border-left: 0;`}
			display: ${props => props.col === 0 || props.colsOpt?.mxs === 0 ? 'none' : 'block'};
			margin: ${props => marginMXS(props?.margin)} ${props => gutterMXS(props.columnGutter) / 2 + `px`} ${props => props?.margin === 'none' ? gutterMXS(props.columnGutter) + `px` : marginMXS(props?.margin)};
			order: ${props => props.orderOpt?.mxs};
			width: calc(((100% / ${size.columns?.mxs}) * ${props => props.colsOpt?.mxs ? props.colsOpt?.mxs : columnSmallest(props.col)}) - ${props => gutterMXS(props.columnGutter)}px);
}`;
