/* eslint-disable camelcase */
import { BID_SALE_AUCTION_STATUS } from 'constants/bidSale';
import dayjs from 'dayjs';
import { getTotalOffersObject } from './bubbles';
import getDealWorkingOfferStatus from './dealWorking';
import getFailedOfferStatus from './failed';
import getLiveOfferStatus from './live';
import getOvertimeOfferStatus from './overtime';
import getPendingOfferStatus from './pending';
import getSoldOfferStatus from './sold';

export const getOfferStatus = ({
	bid_sale_auction_status,
	best_offer_amount,
	best_offer_date,
	best_offer_dealership_name,
	best_offer_dealer_image,
	best_offer_city,
	best_offer_state,
	best_offer_comment,
	best_offer_firm,
	bid_sale_auction_ends_at,
	bid_sale_auction_extended_until,
	currentUserTopOfferer,
	reserve_met,
	my_offer_amount,
	lowest_counter_amount,
	lowest_counter_date,
	lowest_counter_comment,
	lowest_counter_firm,
	loggedUser,
	offerer_id,
	offerer_name,
	offerer_city,
	offerer_state,
	offerer_image,
	total_offers,
	lockedBidHistory,
}) => {
	const isEnded = dayjs(bid_sale_auction_ends_at).isBefore(dayjs());
	const currentUserHasOffered = !!my_offer_amount;
	const hasOffer = !!best_offer_amount;
	const bestOfferUserData = {
		buyer_image: best_offer_dealer_image,
		buyer_name: best_offer_dealership_name,
		buyer_city: best_offer_city,
		buyer_state: best_offer_state,
		amount: best_offer_amount,
	};
	const currentUserData = {
		buyer_image: loggedUser.small_image || loggedUser.normal_image,
		buyer_name: loggedUser.dealership_name,
		buyer_city: loggedUser.city,
		buyer_state: loggedUser.state,
		amount: my_offer_amount,
	};
	const bestOfferUserDataMasterOnBehalfOf = {
		buyer_image: offerer_image,
		buyer_name: offerer_name,
		buyer_city: offerer_city,
		buyer_state: offerer_state,
		amount: my_offer_amount,
	};
	const masterOnBehalfOf = offerer_id !== loggedUser.id;
	const userDataWhenAuctionIsPending = masterOnBehalfOf ? bestOfferUserDataMasterOnBehalfOf : currentUserData;

	let offerStatus;

	switch (bid_sale_auction_status) {
	case BID_SALE_AUCTION_STATUS.live:
		offerStatus = getLiveOfferStatus(currentUserTopOfferer, bestOfferUserData, hasOffer, currentUserHasOffered);
		break;
	case BID_SALE_AUCTION_STATUS.overtime:
		offerStatus = getOvertimeOfferStatus(
			currentUserTopOfferer,
			bestOfferUserData,
			hasOffer,
			currentUserHasOffered,
			isEnded,
		);
		break;
	case BID_SALE_AUCTION_STATUS.deal_working:
		offerStatus = getDealWorkingOfferStatus(
			currentUserTopOfferer,
			bestOfferUserData,
			reserve_met,
			best_offer_amount,
			best_offer_date,
			lowest_counter_amount,
			lowest_counter_date,
			bid_sale_auction_ends_at,
			bid_sale_auction_extended_until,
			best_offer_comment,
			lowest_counter_comment,
			lowest_counter_firm,
			best_offer_firm,
			masterOnBehalfOf,
			best_offer_dealership_name,
		);
		break;
	case BID_SALE_AUCTION_STATUS.sold:
		offerStatus = getSoldOfferStatus(
			currentUserTopOfferer,
			bestOfferUserData,
			reserve_met,
			currentUserHasOffered,
		);
		break;
	case BID_SALE_AUCTION_STATUS.failed:
		offerStatus = getFailedOfferStatus();
		break;
	case BID_SALE_AUCTION_STATUS.pending:
	default:
		offerStatus = getPendingOfferStatus(userDataWhenAuctionIsPending, my_offer_amount);
		break;
	}

	return {
		...offerStatus,
		...getTotalOffersObject(bid_sale_auction_status, total_offers, lockedBidHistory),
	};
};
