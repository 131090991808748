import PropTypes from 'prop-types';

const BlcRowType = {
	/**
	 * Control the content alignment
	 */
	alignContent: PropTypes.oneOf([
		'flex-start',
		'flex-end',
		'center',
		'space-between',
		'space-around',
		'space-evenly',
		'stretch'
	]),
	/**
	 * Control the content vertical alignment
	 */
	alignItems: PropTypes.oneOf([
		'stretch',
		'flex-start',
		'flex-end',
		'center'
	]),
	/**
	 * Control the way the content flows
	 */
	direction: PropTypes.oneOf([
		'row',
		'row-reverse',
		'column'
	]),
	/**
	 * Set the space between columns
	 */
	gutter: PropTypes.oneOf([null,
		'largest',
		'larger',
		'normal',
		'smaller',
		'smallest',
		'none'
	]),
	/**
	 * Set a border between columns
	 */
	divider: PropTypes.bool,
	/**
	 * Set the divider color
	 */
	dividerColor: PropTypes.string,
	/**
	 * Set a fixed height to the Row
	 */
	height: PropTypes.oneOf([
		'unset',
		'0',
		'100%',
		'90%',
		'100vh',
		'fit-content'
	]),
	/**
	 * If father is a flex element, use it to control sizing
	 */
	grow: PropTypes.number,
	/**
	 * Control the content horizontal alignment
	 */
	justifyContent: PropTypes.oneOf([
		'flex-start',
		'flex-end',
		'center',
		'space-between',
		'space-around',
		'space-evenly'
	]),
	/**
	 * Set the Row margin
	 */
	margin: PropTypes.oneOf([
		'largest',
		'larger',
		'normal',
		'smaller',
		'smallest',
		'none'
	]),
	/**
	 * Set the Row position if needed
	 */
	position: PropTypes.oneOf([
		'absolute',
		'relative',
		'static'
	]),
	/**
	 * Control if columns should break into a new line
	 */
	wrap: PropTypes.oneOf([
		'nowrap',
		'wrap',
		'wrap-reverse'
	]),
	/**
	 * Set the Rows's bottom distance if position allows it
	 */
	bottom: PropTypes.number,
	/**
	 * Set the Rows's left distance if position allows it
	 */
	left: PropTypes.number,
	/**
	 * Set the Rows's right distance if position allows it
	 */
	right: PropTypes.number,
	/**
	 * Set the Rows's top distance if position allows it
	 */
	top: PropTypes.number,
	/**
	 * Centers the Row vertically if its parent is a non-static element
	 */
	centerY: PropTypes.bool,
	/**
	 * Set the Row Z index
	 */
	zIndex: PropTypes.number,
	/**
	 * Form specific prop
	 */
	errors: PropTypes.object,
	/**
	 * Form specific prop
	 */
	disabled: PropTypes.bool,
	/**
	 * Form specific prop
	 */
	handleBlur: PropTypes.func,
	/**
	 * Form specific prop
	 */
	isSubmitting: PropTypes.bool,
	/**
	 * Form specific prop
	 */
	setFieldValue: PropTypes.func,
	/**
	 * Form specific prop
	 */
	touched: PropTypes.object,
	/**
	 * Form specific prop
	 */
	values: PropTypes.object
};

export const BlcRowDefaultProps = {
	alignContent: 'stretch',
	alignItems: 'stretch',
	direction: 'row',
	gutter: 'smaller',
	divider: false,
	dividerColor: 'smokeLight',
	height: 'unset',
	grow: 0,
	justifyContent: 'flex-start',
	margin: 'none',
	position: 'static',
	wrap: 'wrap',
	errors: {},
	disabled: false,
	handleBlur: null,
	isSubmitting: false,
	setFieldValue: null,
	touched: {},
	values: {},
	bottom: null,
	left: null,
	right: null,
	top: null,
	centerY: false,
	zIndex: 1
};

export const BlcRowTypeProps = () => (<></>);

BlcRowTypeProps.defaultProps = BlcRowDefaultProps;

BlcRowTypeProps.propTypes = BlcRowType;

export default BlcRowType;
