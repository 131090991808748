import { createAsyncThunk } from '@reduxjs/toolkit';
import BidSalesService from 'services/timedSales/buyside/bidsales.service';
import { calculateSummaryFromList } from 'store/slices/offerSummary/helper';

export const loadBuyerInitialOfferSummary = createAsyncThunk(
	'loadBuyerInitialOfferSummary',
	async ({ loggedUser }) => {
		const loggedUserId = loggedUser.user_id;
		const { data } = await BidSalesService.instance.getBidSaleStats(loggedUser);

		const userSubordinates = null; // it was not working, I just hardcode it to avoid using wrong selector

		const userIds = !userSubordinates ? [loggedUserId] : userSubordinates.map((subordinate) => subordinate.id);

		return {
			bidSalesArr: data,
			stats: calculateSummaryFromList({ bidSalesThatUserHasOfferer: data, userIds }),
			userIds,
		};
	},
);
