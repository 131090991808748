export default [
	`undefined is not an object (evaluating 'window.webkit.messageHandlers[a].postMessage')`,
	`undefined is not an object (evaluating '__gCrWeb.instantSearch.clearHighlight')`,
	`undefined is not an object (evaluating '__gCrWeb.edgeTranslate.detectPageState')`,
	`Can't find variable: setIOSParameters`,
	`Can't find variable: instantSearchSDKJSBridgeClearHighlight`,
	`Can't find variable: SymBrowser_ModifyWindowOpenWithTarget`,
	`Can't find variable: SymBrowser_ModifyAnchorTagWithTarget`,
	`Cannot read properties of null (reading 'removeChild')`,
	`Cannot read properties of undefined (reading 'cssRules')`,
	`Cannot redefine property: BetterJsPop`,
	'Cannot redefine property: googletag',
	`can't redefine non-configurable property "userAgent"`,
];

const MINIFIED_REACT_ERROR = 'Minified React error';

export const isMinifiedReactError = (errorMessage) => {
	try {
		return errorMessage.startsWith(MINIFIED_REACT_ERROR);
	} catch {
		return false;
	}
};
