export const columnLarger = (cols) => {
	switch (cols) {
	case 12:
		return 8;
	case 11:
		return 7;
	case 10:
		return 7;
	case 9:
		return 6;
	case 8:
		return 6;
	case 7:
		return 5;
	case 6:
		return 4;
	case 5:
		return 3;
	case 4:
		return 2;
	case 3:
		return 2;
	case 2:
		return 1;
	case 1:
		return 1;
	default:
		return 8;
	}
};

export const columnSmaller = (cols) => {
	switch (cols) {
	case 12:
		return 6;
	case 11:
		return 5;
	case 10:
		return 5;
	case 9:
		return 4;
	case 8:
		return 4;
	case 7:
		return 3;
	case 6:
		return 3;
	case 5:
		return 3;
	case 4:
		return 2;
	case 3:
		return 2;
	case 2:
		return 1;
	case 1:
		return 1;
	default:
		return 6;
	}
};

export const columnSmallest = (cols) => {
	switch (cols) {
	case 12:
		return 4;
	case 11:
		return 4;
	case 10:
		return 4;
	case 9:
		return 4;
	case 8:
		return 4;
	case 7:
		return 2;
	case 6:
		return 2;
	case 5:
		return 2;
	case 4:
		return 4;
	case 3:
		return 4;
	case 2:
		return 4;
	case 1:
		return 4;
	default:
		return 4;
	}
};

export const breakPointPx = (size, prop) => {
	switch (size) {
	case 'mxl':
		return prop >= 150 * 8 ? '100%' : prop + 'px';
	case 'ml':
		return prop >= 124 * 8 ? '100%' : prop + 'px';
	case 'ms':
		return prop >= 96 * 8 ? '100%' : prop + 'px';
	case 'mxs':
		return prop >= 60 * 8 ? '100%' : prop + 'px';
	default:
		return prop + 'px';
	}
};
