import BlcLayoutBase from 'components/BlcLayout/BlcLayout.base.js';
import BlcTopbar from 'components/BlcNav/Topbar';
import prop, { BlcStaticPageDefaultProps } from 'components/BlcLayout/StaticPage/props.js';

const blcStaticPage = (props) => (
	<BlcLayoutBase {...props}>
		<BlcTopbar background="transparent" />
		{props.children}
	</BlcLayoutBase>
);

blcStaticPage.propTypes = prop;

blcStaticPage.defaultProps = BlcStaticPageDefaultProps;

export default blcStaticPage;
