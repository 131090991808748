import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import BlcStaticPage from 'components/BlcLayout/StaticPage';
import SectionLanding from 'partials/error/_sectionLanding.js';
import { notifyError } from 'helpers/errorNotifier';
import UserProvider from 'helpers/user/userProvider';
import { getUser } from 'helpers/user/getUser';
import STATUS_CODES from 'constants/statusCodes';

const Error = (props) => (
	<BlcStaticPage id="error-page" t={props.t}>
		<SectionLanding statusCode={props.statusCode} />
	</BlcStaticPage>
);

export async function getServerSideProps (ctx) {
	const { res, err, locale = 'en' } = ctx;
	const user = await getUser(ctx, false);

	if (err && err.statusCode && err.statusCode != STATUS_CODES.NOT_FOUND) {
		notifyError(err);
	}
	let statusCode = STATUS_CODES.INTERNAL_SERVER_ERROR;
	statusCode = err ? err.statusCode : statusCode;
	statusCode = res ? res.statusCode : statusCode;

	return {
		props: {
			statusCode,
			...(await serverSideTranslations(locale, ['static'])),
			user: { ...user },
		},
	};
}

export default UserProvider(Error);
