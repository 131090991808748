import { buildParamsURL } from 'helpers/search';

const getHeaders = (user) => {
	const { auth, jwt } = user;
	const headers = {
		Accept: 'application/json; application/vnd.backlotcars.v3;',
		'Content-Type': 'application/json',
		'X-Configuration-Definition-ID': '1',
	};
	return auth ? { ...headers, 'X-User-JWT': jwt } : headers;
};

const getConditionReportUrl = (id, vdp, router) => {
	const baseURL = vdp ? '/api/condition_reports' : '/api/condition_report';
	if (!vdp) {
		return `${baseURL}/${router.query?.reportid}`;
	} else {
		return id ? `${baseURL}/${id}?request_images=true` : null;
	}
};

const getConditionReportPageUrl = (token, partnerName, disablePartnerScript) => {
	const params = buildParamsURL({ partner_name: partnerName, disable_partner_script: disablePartnerScript });
	return `/condition-report/${token}${params ? `?${params}` : ''}`;
};

export { getConditionReportUrl, getHeaders, getConditionReportPageUrl };
