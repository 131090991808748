import { Children, cloneElement } from 'react';
import prop, { BlcRowDefaultProps } from 'components/BlcGrid/Row/props.js';
import { BlcRowWrapper } from 'components/BlcGrid/Row/style.js';

/**
 * @deprecated
 * Use component from '@Backlot-Cars/archie'
 */
const BlcRow = (props) => {
	return (
		<BlcRowWrapper
			className={props.className}
			alignContent={props.alignContent}
			alignItems={props.alignItems}
			centerY={props.centerY}
			direction={props.direction}
			grow={props.grow}
			CSSHeight={props.height}
			justifyContent={props.justifyContent}
			margin={props.margin}
			gutter={props.gutter}
			position={props.position}
			zIndex={props.zIndex}
			wrap={props.wrap}
			top={props.top}
			right={props.right}
			bottom={props.bottom}
			left={props.left}
		>
			{ Children.map(props.children, child => cloneElement(child,
				{
					columnGutter: props.gutter,
					columnDivider: props.divider,
					columnDividerColor: props.dividerColor,
					/* Only required on under Form usage */
					errors: props.errors,
					disabled: props.isSubmitting,
					handleBlur: props.handleBlur,
					isSubmitting: props.isSubmitting,
					setFieldValue: props.setFieldValue,
					touched: props.touched,
					values: props.values
				}))
			}
		</BlcRowWrapper>
	);
};

BlcRow.propTypes = prop;

BlcRow.defaultProps = BlcRowDefaultProps;


export default BlcRow;
