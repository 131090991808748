import PropTypes from 'prop-types';

const BlcStaticPageType = {
	/**
	 * Set the static page id
	 */
	id: PropTypes.string,
	/**
	 * Set if the static page has navigation
	 */
	navigation: PropTypes.bool,
	/**
	 * Set the static page children
	 */
	children: PropTypes.node,
	/**
	 * Set the static page translation function
	 */
	t: PropTypes.func
};

export const BlcStaticPageDefaultProps = {
	navigation: true
};

export const BlcStaticPageTypeProps = () => (<></>);

BlcStaticPageTypeProps.defaultProps = BlcStaticPageDefaultProps;

BlcStaticPageTypeProps.propTypes = BlcStaticPageType;

export default BlcStaticPageType;
