import { deal_working, live_or_extended, sold } from 'helpers/timedSales/auctionStatusScenarios';

export const calculateSummaryFromList = ({ bidSalesThatUserHasOfferer, userIds }) => {
	const offerSummary = bidSalesThatUserHasOfferer.reduce(
		({ leaderCount, outbiddedCount, wonCount, lostCount, counterCount }, bidSale) => {
			const isUserTheTopOfferer = userIds.includes(bidSale.top_offerer_id);

			if (live_or_extended(bidSale.auction_status)) {
				if (deal_working(bidSale.auction_status)) {
					isUserTheTopOfferer ? counterCount++ : lostCount++;
				} else {
					isUserTheTopOfferer ? leaderCount++ : outbiddedCount++;
				}
			}
			if (sold(bidSale.auction_status)) {
				isUserTheTopOfferer ? wonCount++ : lostCount++;
			}

			return { leaderCount, outbiddedCount, wonCount, lostCount, counterCount };
		},
		{ leaderCount: 0, outbiddedCount: 0, wonCount: 0, lostCount: 0, counterCount: 0 },
	);

	return offerSummary;
};
