/* eslint-disable camelcase */
import { authenticatedRestClient } from 'helpers/restclient';
import { getAuthHeaders } from 'helpers/restclient/auth';

export default class UserSessionService {
	static instance = new UserSessionService();

	_endpoints = {
		logout: '/api/users/sessions',
		groupAccounts: '/api/sellers/masters/users',
		changeAccount: '/master_users/sessions',
	};

	constructor() {
		this.restClient = authenticatedRestClient;
	}

	logout = (user) =>
		this.restClient.delete(`${this._endpoints.logout}`, {}, getAuthHeaders(user));

	getGroupAccounts = async (user) =>
		await this.restClient.get(this._endpoints.groupAccounts, {}, getAuthHeaders(user));

	changeAccount = async (id, user) => {
		const { data } = await this.restClient.post(
			this._endpoints.changeAccount,
			{ user_id: id },
			getAuthHeaders(user),
		);
		return data?.redirect_path;
	};

	reloadUser = async (user) => {
		await this.restClient.post('/users/cookie', {}, getAuthHeaders(user));
	};
}
