import { Children, cloneElement } from 'react';
import prop, { BlcColumnDefaultProps } from 'components/BlcGrid/Column/props.js';
import { Wrapper }	from 'components/BlcGrid/Column/style.js';
/**
 * @deprecated
 * Use component from '@Backlot-Cars/archie'
 */
const blcColumn = props => (
	<Wrapper className="column"
		CSSHeight={props.height}
		CSSOrder={props.order}
		columnDividerColor={props.columnDividerColor}
		col={props.col}
		columnDivider={props.columnDivider}
		columnGutter={props.columnGutter}
		colsOpt={props.colsOpt}
		dividerOpt={props.dividerOpt}
		margin={props.margin}
		minHeight={props.minHeight}
		position={props.position}
		orderOpt={props.orderOpt}
		minHeightMobile={props.minHeightMobile}
		align={props.align}
		top={props.top}
		right={props.right}
		bottom={props.bottom}
		left={props.left}
		size={props.size}>
		{Children.map(props.children, child => {
			const _props =
				typeof child.type === 'string'
					? {}
					: {
						/* Only required by Form */
						errors: props.errors,
						disabled: child.props.disabled ? child.props.disabled : props.isSubmitting,
						handleBlur: props.handleBlur,
						isSubmitting: props.isSubmitting,
						setFieldValue: props.setFieldValue,
						touched: props.touched,
						values: props.values
					  };
			return cloneElement(child, _props);
		})}
	</Wrapper>
);

blcColumn.propTypes = prop;

blcColumn.defaultProps = BlcColumnDefaultProps;

export default blcColumn;
