import { useTranslation } from 'next-i18next';

import { BlcText, Body, Headline, BlcBox, BlcContainer, BlcSection } from '@Backlot-Cars/archie';
import { BlcColumn, BlcRow } from 'components/BlcGrid';
import STATUS_CODES from 'constants/statusCodes';

const SectionLanding = (props) => {
	const { t } = useTranslation('static');

	return (
		<BlcSection background="asphalt" fullHeight id="" padding="none">
			<BlcContainer size="largest">
				<BlcRow alignItems="center" centerY justifyContent="center">
					<BlcColumn colsOpt={{ mxl: 5, ml: 3, ms: 6, mxs: 4 }} orderOpt={{ mxl: 0, ml: 0, ms: 1, mxs: 1 }}>
						<BlcBox background="asphalt">
							<Headline bold size="H1" color="white" margin="largest">
								{props.statusCode === STATUS_CODES.NOT_FOUND ? t('PageNotFound') : t('InternalServerError')}
							</Headline>
							<Body color="white" margin="largest" size="largest">
								{props.statusCode === STATUS_CODES.NOT_FOUND
									? t('ThePageYouAreLookingForHasNotBeenFound')
									: t('TheServerEncounteredAnInternalError')}
							</Body>
						</BlcBox>
					</BlcColumn>
					<BlcColumn colsOpt={{ mxl: 7, ml: 5, ms: 6, mxs: 4 }} orderOpt={{ mxl: 1, ml: 1, ms: 0, mxs: 0 }}>
						<BlcBox background="asphalt" textAlign="right">
							<BlcText color="primary" display="block" fontSizeVw={30} fontWeight="bold">
								{`${props.statusCode}`}
							</BlcText>
						</BlcBox>
					</BlcColumn>
				</BlcRow>
			</BlcContainer>
		</BlcSection>
	);
};
export default SectionLanding;
