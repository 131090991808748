import { gutter, gutterML, gutterMS, gutterMXS } from 'helpers/gutter';
import { margin, marginML, marginMS, marginMXS } from 'helpers/margin';
import { media } from 'public/styles';
import styled from 'styled-components';

export const BlcRowWrapper = styled.div`
align-content: ${props => props.alignContent};
align-items: ${props => props.alignItems};
bottom: ${props => props.centerY ? 'unset' : props.bottom};
display: flex;
flex-direction: ${props => props.direction};
flex-grow: ${props => props.grow};
flex-wrap: ${props => props.wrap};
height: ${props => props.CSSHeight};
justify-content: ${props => props.justifyContent};
left: ${props => props.left};
margin: ${props => margin(props.margin)} ${props => (gutter(props.gutter) / -2)}px;
position: ${props => props.centerY ? 'absolute' : props.position};
right: ${props => props.right};
top: ${props => props.centerY ? '50%' : props.top};
transform: ${props => props.centerY ? 'translateY(-50%)' : null};
width: calc(100% + ${props => gutter(props.gutter)}px);
z-index: ${props => props.zIndex};

@media ${media.larger} {
	margin: ${props => marginML(props.margin)} ${props => (gutterML(props.gutter) / -2)}px;
	width: calc(100% + ${props => gutterML(props.gutter)}px);
}
@media ${media.smaller} {
	margin: ${props => marginMS(props.margin)} ${props => (gutterMS(props.gutter) / -2)}px;
	width: calc(100% + ${props => gutterMS(props.gutter)}px);
}
@media ${media.smallest} {
	margin: ${props => marginMXS(props.margin)} ${props => (gutterMXS(props.gutter) / -2)}px;
	width: calc(100% + ${props => gutterMXS(props.gutter)}px);
}`;
