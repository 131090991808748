import sizeHelper from 'helpers/size';

/**
 * Margins
 */
export const margin = (sizeName) => {
	return sizeHelper('margin', sizeName, null, 'mxl', 'zero');
};
/* Media queries: */
export const marginML = (sizeName) => {
	return sizeHelper('margin', sizeName, null, 'ml', 'zero');
};
export const marginMS = (sizeName) => {
	return sizeHelper('margin', sizeName, null, 'ms', 'zero');
};
export const marginMXS = (sizeName) => {
	return sizeHelper('margin', sizeName, null, 'mxs', 'zero');
};
/**
 * Section Paddings
 */
export const sectionPadding = (sizeName) => {
	return sizeHelper('sectionPadd', sizeName, null, 'mxl', 'zero');
};
/* Media queries: */
export const sectionPaddingML = (sizeName) => {
	return sizeHelper('sectionPadd', sizeName, null, 'ml', 'zero');
};
export const sectionPaddingMS = (sizeName) => {
	return sizeHelper('sectionPadd', sizeName, null, 'ms', 'zero');
};
export const sectionPaddingMXS = (sizeName) => {
	return sizeHelper('sectionPadd', sizeName, null, 'mxs', 'zero');
};
