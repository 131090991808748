import { OpenLaneLogo, colors } from '@Backlot-Cars/archie';
import { useRouter } from 'next/router';
import { media, size } from 'public/styles';

const BlcTopbar = ({ background = 'transparent' }) => {
	const router = useRouter();
	const { hideLogo } = router.query;

	return (
		<header>
			{!hideLogo && <OpenLaneLogo width={84} height={64} variant="vertical" />}

			<style jsx>{`
				header {
					align-items: center;
					background-color: ${colors[background]};
					display: flex;
					height: ${size.header.mxl};
					justify-content: space-between;
					left: 0;
					padding: 0 ${size.margin.normal.mxl};
					position: absolute;
					top: 0;
					transition: background 0.15s ease-in-out;
					z-index: 10;
					width: 100%;
				}

				@media ${media.largest} {
					header {
						height: ${size.header.mxl};
						padding: 0 ${size.margin.normal.mxl};
					}
				}
				@media ${media.larger} {
					header {
						height: ${size.header.mxl};
						padding: 0 ${size.margin.normal.ml};
					}
				}
				@media ${media.smaller} {
					header {
						height: ${size.header.mxl};
						padding: 0 ${size.margin.normal.ms};
					}
				}
				@media ${media.smallest} {
					header {
						height: ${size.header.mxl};
						padding: 0 ${size.margin.normal.mxs};
					}
				}
			`}</style>
		</header>
	);
};

export default BlcTopbar;
