import Pusher from 'pusher-js';
import { useEffect, useState } from 'react';

const usePusher = (channelName, events, callback, data) => {
	const [pusherChannel, setPusherChannel] = useState();
	useEffect(() => {
		let pusher;
		if (process.env.PUSHER_ENABLED && channelName && events.length) {
			try {
				pusher = new Pusher(process.env.PUSHER_APP_KEY, {
					encrypted: true,
					authEndpoint: `${process.env.RESTURL_BASE}/pusher/auth`,
				});
				const channel = pusher.subscribe(channelName);
				setPusherChannel(channel);
			} catch (e) {
				console.error('error creating pusher instance. If key is missing try running dotenv:decrypt', e);
			}
		}

		return () => {
			if (pusher) {
				pusher.disconnect();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (pusherChannel && pusherChannel.bind) {
			events.forEach((event) => {
				pusherChannel.unbind(event);
				pusherChannel.bind(event, callback);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pusherChannel, data]);
};

export default usePusher;
